import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, Store } from 'redux';
import { isServer, loadScript } from '../../../utils';
import { ConnectedHelmet } from '../../atoms/ConnectedHelmet/ConnectedHelmet';
import { environment } from '../../config/environment';
import { ActionTypes } from '../../models/Analytics';
import { ArenaConfig } from '../../models/ArenaConfig';
import { Game } from '../../models/Game';
import { HeaderMenu } from '../../models/HeaderMenu';
import { LoginCaller } from '../../models/Login';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { AnalyticsGeneral } from '../../services/Analytics/AnalyticsGeneral';
import { GeoService } from '../../services/GeoService';
import { UrlService } from '../../services/UrlService';
import { gamesByLangSelector } from '../../store/ducks/games';
import {
  setHeaderGemsAmount,
  setHeaderMenu,
  setHeaderParentSiteUrl,
  setHeaderTheme,
  setHeaderUserCountry,
} from '../../store/ducks/header';
import { AppState } from '../../store/types';
import { EventCategoryService, frenchTextFix } from '../../services/EventCategoryService';
import { GameUnitState } from '../../models/Enums';
import GemsService from '../../services/GemsService';
import { setGemsAmount } from '../../store/ducks/gems';
import './Redesign/Styles/Layout.css';

type HeaderProps = {
  store: Store;
  dispatch: Dispatch;
  config: ArenaConfig;
  userAuthStatus: string;
  games: Game[];
  lang: string;
  isEventCategoryOn: boolean;
  gameUnitState: GameUnitState;
  currentPathname: string;
  gems: number;
};

class HeaderBase extends React.PureComponent<HeaderProps & WithTranslation> {
  constructor(props) {
    super(props);

    if (!isServer) {
      this.loadArenaHeaders();
    }
  }

  getUserCountry = () => {
    GeoService.getUserCountry()
      .then((country) => this.props.dispatch(setHeaderUserCountry(country)))
      .catch((err) => {
        this.props.dispatch(setHeaderUserCountry('default'));
        console.log(err.message);
      });
  }

  getHeaderFilename() {
    const client = (this.props.config.theme.client || 'default').toLowerCase();

    // uncomment this block to test headers you built locally
    // return `http://localhost:9000/app/clients/${client}/${client}`;

    return `${environment.ARENA_HEADERS_BLOB}/${client}/${client}`;
  }

  loadArenaHeaders = () => {
    const path = this.getHeaderFilename();

    loadScript(path + '.js').then((_) => {
      (window as any).arena51ClientApi.setStore(this.props.store);
    });
  }

  buildCategoriesList() {
    const {
      t,
      config,
      games,
      lang,
      isEventCategoryOn,
    } = this.props;
    const categories = config.categories.allCategories;
    const navigationTags = config.tags;
    const fullCategories = config.categories.homeCategories;
    const eventCatNamesList = EventCategoryService.getEventCatNamesList(fullCategories);

    return categories.filter((category) => {
      const navTag = navigationTags.find((tag) => tag.name === category);

      if (navTag && !navTag.visible) {
        return;
      }

      const translated = EventCategoryService.categoryTranslated(category, t(category.toUpperCase()), lang);

      return (
        games.some((game) => game.hasCategory(translated)) &&
        (isEventCategoryOn ? true : !eventCatNamesList.includes(translated.toLowerCase()))
      );
    });
  }

  buildMenu = (): HeaderMenu | any => {
    const {
      t,
      i18n,
      config,
      lang,
    } = this.props;
    const subFolder = config.theme.subfolder ?? '';
    let baseHref =  UrlService.getWindowBaseHref(); // subFolder ? 'https://' + window.location.host + '/' + subFolder :
    const fullCategories = config.categories.homeCategories;
    const nonEmptyCategories = this.buildCategoriesList();
    const unusedCategoryList = [];
    const finalCategoryList = new Set();

    fullCategories
      .filter((fullCat) => fullCat?.eventCat)
      .forEach((fullCat) => {
        Object.keys(fullCat.eventCat.eventNameLocales).forEach((locale) => {
          if (
            locale !== lang &&
            fullCat.eventCat.eventNameLocales[locale] !== fullCat.eventCat.eventNameLocales[lang]
          ) {
            unusedCategoryList.push(fullCat.eventCat.eventNameLocales[locale]);
          }
        });
      });

    nonEmptyCategories.forEach((category) => {
      if (!unusedCategoryList.includes(category)) {
        finalCategoryList.add(category);
      }
    });
    let url = baseHref;
    const sub = subFolder  ? '/' + subFolder : '';

    return ['HOME', ...finalCategoryList, 'ALL_GAMES'].map((category: string) => {
      const title: string = i18n.exists(category.toUpperCase()) ? t(category.toUpperCase()) : category;

      switch (category) {
        case 'HOME':
          url =  '/' + subFolder;
          break;
        case 'ALL_GAMES':
          url =  sub + `/${t('ROUTES.ALL_GAMES')}`;
          break;
        default:
          url =  sub + `/${t('ROUTES.CATEGORY')}/${UrlService.serializeCategoryUrl(title)}`;
      }

      return {
        title: frenchTextFix(title),
        url,
        category,
      };
    });
  }

  updateGemsInStoreAndHeader = (gems: number | null) => {
    const { dispatch } = this.props;

    dispatch(setGemsAmount(gems));
    dispatch(setHeaderGemsAmount(gems?.toString()));
  }

  componentDidMount() {
    const {
      config,
      dispatch,
    } = this.props;

    this.getUserCountry();

    const menu = this.buildMenu();
    const parentUrl = config.theme.clientHomePageUrl || UrlService.getWindowBaseHref();

    dispatch(setHeaderParentSiteUrl(parentUrl));

    dispatch(
      setHeaderTheme({
        logo: config.theme.logoPath,
        background: config.theme.headerBgColorHex,
        color: config.theme.headerTextColorHex,
        disableLogin: config.theme.disableLogin,
        balance: '0',
      }),
    );

    dispatch(setHeaderMenu(menu));
    AnalyticsGeneral.topNavCategories(
      ActionTypes.IMPRESSION,
      `Logo|${menu.map((tag: { title: string }) => tag.title).join('|')}`,
    );
  }

  async componentDidUpdate(prevProps: HeaderProps) {
    if (prevProps.userAuthStatus !== this.props.userAuthStatus) {
      if (this.props.userAuthStatus === UserAuthStatus.USER_NOT_AUTHORIZED) {
        AnalyticsGeneral.login(ActionTypes.IMPRESSION, LoginCaller.TopNav);
      }

      if (this.props.userAuthStatus === UserAuthStatus.USER_AUTHORIZED && this.props.config.isGemsSupport) {
        await GemsService.getUserGemsAmount()
          .then(this.updateGemsInStoreAndHeader)
          .catch((e) => {
            console.log(e);
          });
      }
    }

    if (prevProps.gems !== this.props.gems) {
      this.updateGemsInStoreAndHeader(this.props.gems);
    }
  }

  render() {
    const isSubscription = this.props.currentPathname === `/${this.props.t('ROUTES.SHOP')}`;

    if (isSubscription) {
      return null;
    }

    const helmet = (
      <ConnectedHelmet>
        <link rel="stylesheet" href={this.getHeaderFilename() + '.css'}/>
      </ConnectedHelmet>
    );

    if (isServer) {
      return helmet;
    }

    if (this.props.gameUnitState) {
      return null;
    }

    return (
      <React.Fragment>
        <header>
          {helmet}
          <ark-header-component/>
        </header>
      </React.Fragment>
    );
  }
}

const TranslatedHeader = withTranslation()(HeaderBase);

export const Header = connect((state: AppState, _) => ({
  config: state.config,
  games: gamesByLangSelector(state),
  userAuthStatus: state.userAuthStatus,
  lang: state.currentLang,
  gameUnitState: state.gameUnitState,
  gems: state.gems,
  currentPathname: state.router.location.pathname,
}))(TranslatedHeader);
